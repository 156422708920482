<template>
    <div>
        <section class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4">
            <div class="flex flex-row justify-between items-center">
                <form class="relative">
                    <svg width="20" height="20" fill="currentColor" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" />
                    </svg>
                    <input v-model="search" class="focus:border-light-blue-500 focus:ring-1 focus:ring-red-400 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-300 rounded-md py-2 pl-10" type="text" aria-label="Filter projects" placeholder="Recherche par année" />
                </form>
                <slot />
            </div>
            <ul class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-4">
                <li v-for="item in filterYears" :key="item.id" @click="passeYear(item.id)">
                <a class="hover:bg-light-blue-500 hover:bg-red-400 hover:shadow-lg group block rounded-lg p-4 border border-gray-200" :class="{'bg-gray-200' : selectedYear==item.id}">
                    <dl class="grid sm:block lg:grid xl:block grid-cols-2 sm:grid-rows-2 justify-center items-center">
                    <div>
                        <dt class="sr-only">Title</dt>
                        <dd class="group-hover:text-white leading-6 font-medium text-black">
                            {{item.title}}
                        </dd>
                    </div>
                    <div>
                        <dt class="sr-only">Category</dt>
                        <dd class="flex flex-row justify-center group-hover:text-light-blue-200 text-center p-5 font-medium sm:mb-4 lg:mb-0 xl:mb-4">
                            <p class="text-5xl mx-2"> {{ item.id }} </p>
                            <p class="ordinal">an</p>
                        </dd>
                    </div>
                    </dl>
                </a>
                </li>
            </ul>
        </section>
    </div>
</template>

<script>
export default {

  data () {
     return {
        search: '',
        selectedYear: '',
        years:[
            {
                id: 1,
                title: 'Première Année',
            },
            {
                id: 2,
                title: 'Deuxième Année',
            },
            {
                id: 3,
                title: 'Troisième Année',
            },
            {
                id: 4,
                title: 'Quatrième Année',
            },
            {
                id: 5,
                title: 'Cinquième Année',
            },
            {
                id: 6,
                title: 'Sixième Année',
            },
        ]
     }
   },

    methods : {
        passeYear(year){
            this.selectedYear = year
            this.$emit('year',this.selectedYear)
        }
    },

  computed:{
        filterYears(){
            if(this.search){
                return this.years.filter((item)=>{
                    return item.title.toLowerCase().includes(this.search.toLowerCase())
                })
            }else{
                return this.years
            }
        },
  }
}
</script>

<style>

</style>